import React, { useEffect, useState } from 'react';

import { INTEGRATIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { isEqual } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import SyncModal from '~/components/SyncModal';
import AvatarCard from '~/components/UI/AvatarCard';

import { DescriptionWrapper } from './design';

import usersIcon from '~/assets/a-users-icn.svg';

import { INTEGRATIONS_CONN_ERROR_MSG, SYNC_MEMBERS_DESCRIPTIONS } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import { findMembersToSyncInCompanyIntegrations } from '~/services/integrations';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

import { ICON_SIZES } from '../Icon';
import { WarningIcon } from '../InviteUsersModal/components/WarningIcon';

function Description({ item, memberDesc, i18n, integration }) {
  return integration?.externalSoftware === INTEGRATIONS.KOMBO && item.warning ? (
    <DescriptionWrapper>
      <WarningIcon message={item.warning} size={ICON_SIZES.MEDIUM} />
      {memberDesc.description(i18n, item)}
    </DescriptionWrapper>
  ) : (
    <>{memberDesc.description(i18n, item)}</>
  );
}

function SyncMembersModal({ i18n, onClose, onSubmit }) {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncLoading, setSyncLoading] = useState(false);
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const itemsPath = 'result.changes'; // path where to get items
  const searchByField = 'fullName'; // field used when searching items

  useEffect(() => {
    const fetchData = async () => {
      let integrationsWithSyncMembers;
      try {
        integrationsWithSyncMembers = await findMembersToSyncInCompanyIntegrations();
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!integrationsWithSyncMembers) {
        setIsIntegrationError(true);
        setLoading(false);
        return;
      }

      if (!isEmpty(integrationsWithSyncMembers)) {
        setIntegrations(orderBy(integrationsWithSyncMembers, ['name']));
      }

      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const cols = [
    {
      title: i18n._(t`Member`),
      width: '248px',
    },
    {
      title: i18n._(t`Type`),
      width: '140px',
    },
    { title: i18n._(t`Description`) },
  ];

  return (
    <SyncModal
      title={i18n._(t`Synchronise members`)}
      integrations={integrations}
      cols={cols}
      searchByField={searchByField}
      loading={loading}
      buttonLoading={syncLoading}
      onClose={onClose}
      onSubmit={() => {
        if (!isEmpty(integrations)) {
          const membersToSync = [];
          integrations.map((i) => membersToSync.push(...i.result.update));
          setSyncLoading(true);
          return onSubmit(membersToSync);
        }
      }}
      placeholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No API available`)}
          subTitle={i18n._(
            isIntegrationError
              ? INTEGRATIONS_CONN_ERROR_MSG
              : t`Integrate Learned with your HR systems. Contact your customer success manager.`,
          )}
          subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
        />
      }
      infoMessage={i18n._(
        t`Members can only be synchronised if they have been imported via an API and can only be synchronised with that specific API.`,
      )}
      infoUrl={getInstructionUrl(INSTRUCTIONS.SYNC_MEMBERS)}
      confirmMessage={i18n._(
        t`Are you sure you want to synchronise this members from Learned? This cannot be undone!`,
      )}
      isSubmitDisabled={integrations.every((i) => isEmpty(get(i, itemsPath, [])))}
      pathToItems={itemsPath}
      getIntegrationRowData={(item) => {
        // TO DO: add warning regarding coach status change, if item.warning exists, it will already contain a translated string
        const memberDesc = Object.values(SYNC_MEMBERS_DESCRIPTIONS).find(
          (desc) => desc.key === item.type,
        );
        const integration = integrations.find((integration) =>
          integration.result.changes.find((change) => isEqual(change, item)),
        );
        return [
          <AvatarCard key={0} type={AvatarCard.TYPES.SMALL} userId={item.userId} />,
          memberDesc.label(i18n),
          <Description
            key={item.userId}
            item={item}
            memberDesc={memberDesc}
            i18n={i18n}
            integration={integration}
          />,
        ];
      }}
      integrationPlaceholder={
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={usersIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No member changes to synchronise`)}
        />
      }
      integrationExplanation={i18n._(
        t`These changes have been detected. Click synchronise to apply the changes.`,
      )}
    />
  );
}

SyncMembersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withI18n()(SyncMembersModal);
