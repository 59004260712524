import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import CompanyAvatar from '~/components/CompanyAvatar';
import GiveFeedback from '~/components/GiveFeedback';
import { GiveFeedbackLocked } from '~/components/GiveFeedbackLocked';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import Avatar from '~/components/UI/Avatar';

import checkIcon from '~/assets/icon-checkmark-rounded.svg';

import {
  REQUEST_TYPES,
  REQUEST_STATUSES,
  RATING_TYPES,
  REVIEW_STATUSES,
  OUTSIDE_PAGES,
} from '~/constants';
import getAppThemeUrl from '~/selectors/getAppThemeUrl';
import { axiosCloud } from '~/server_config';
import { getCompanyOutside } from '~/services/companies';
import { getReview } from '~/services/reviews';
import { getUserAvatar, getUserLocale } from '~/services/users';
import { finishSetTheme } from '~/store/appTheme/actions';
import * as currentRequestActions from '~/store/currentRequest/actions';
import * as currentReviewActions from '~/store/currentReview/actions';
import { changeLang } from '~/store/locale/actions';
import { finishGetUsers } from '~/store/users/actions';
import { COLORS, COLOR_PALETTE } from '~/styles';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh);
`;

const SuccessBlockWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_PALETTE.WHITE};
`;

const SuccessBlock = styled.div`
  align-items: center;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 400px;
`;

const CompanyIcons = styled.div`
  display: flex;

  & > * {
    margin: 0 4px;
  }
`;

const SuccessTitle = styled.h1`
  color: ${COLORS.TEXT_BLACK};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin: 24px;
`;

const SuccessDescription = styled.p`
  color: ${COLORS.TEXT_SECONDARY};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 16px;
`;

const INITIAL_STATE = {
  isFeedbackActive: false,
  isFeedbackSigned: false,
  isStartDateInFuture: false,
  loading: true,
  review: null,
  fromUser: null,
  email: null,
  request: null,
  error: null,
  companyColor: null,
};

// us this to set company lang only on first load
let isFirstRender = true;

export class ReviewGiveFeedbackOutside extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    // get params from url
    let href = window.location.href;
    let url = new URL(href);
    let fromUserId = url.searchParams.get('fromUser');
    let email = url.searchParams.get('email');
    let reviewId = url.searchParams.get('reviewId');
    let fromUserName = url.searchParams.get('fromUserName');
    axiosCloud.defaults.headers.common.page = OUTSIDE_PAGES.REVIEW;
    axiosCloud.defaults.headers.common.email = email;
    axiosCloud.defaults.headers.common['token-outside'] = reviewId;

    dispatch(currentReviewActions.setCurrentReview({}));
    dispatch(currentRequestActions.setCurrentRequest({}));
    if (fromUserId && email && reviewId) {
      const [review, fromUserAvatar, locale] = await Promise.all([
        await getReview(reviewId, {
          join: ['requests', 'ratings'],
          isQuestions: true,
          isInterviewee: true,
        }),
        await getUserAvatar(fromUserId),
        await getUserLocale(fromUserId),
      ]);

      // request cancelled
      if (review && review.error) {
        this.setState({ error: review.error });
        return;
      }

      // set locale
      if (isFirstRender) {
        dispatch(changeLang(locale));
      }

      if (review) {
        const { logoUrl, color } = await getCompanyOutside(review.company);
        this.setState({ companyColor: color });
        dispatch(finishSetTheme({ logoUrl }));

        const request = (review.requests || []).find(
          (request) =>
            request.type === REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER && request.toEmail === email,
        );

        const isFeedbackActive =
          request &&
          review.status !== REVIEW_STATUSES.DONE.key &&
          request.status !== REQUEST_STATUSES.CANCELLED.key &&
          request.status !== REQUEST_STATUSES.EXPIRED.key &&
          request.status !== REQUEST_STATUSES.SHARED.key;

        const isFeedbackGiven = request && request.status === REQUEST_STATUSES.SHARED.key;
        const isStartDateInFuture = !isTaskStartDateInThePast(get(review, 'startDateUsersRequest'));
        const forUser = {
          id: fromUserId,
          firstName: fromUserName,
          avatarUrl: fromUserAvatar,
        };

        dispatch(currentReviewActions.setCurrentReview(review));
        dispatch(currentRequestActions.setCurrentRequest(request));
        dispatch(
          finishGetUsers({
            [forUser.id]: forUser,
          }),
        );

        this.setState({
          forUser,
          email,
          isFeedbackActive,
          isFeedbackGiven,
          isFeedbackSigned: (review?.signatures || []).length > 0,
          isStartDateInFuture,
        });

        isFirstRender = false;
      }
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    delete axiosCloud.defaults.headers.common.page;
    delete axiosCloud.defaults.headers.common.email;
    delete axiosCloud.defaults.headers.common['token-outside'];
  }

  renderNotActiveOptions() {
    const { review, logoUrl, i18n } = this.props;
    const { isFeedbackGiven, companyColor } = this.state;

    return isFeedbackGiven ? (
      <SuccessBlockWrapper>
        <SuccessBlock>
          <CompanyIcons>
            <CompanyAvatar logoUrl={logoUrl} size={56} />
            <Avatar userId={review.createdFor} size={56} showTooltip={false} />
          </CompanyIcons>
          <SuccessTitle>
            <Trans>Thanks for your feedback</Trans>
          </SuccessTitle>
          <SvgIcon
            isDefaultColor
            defaultColor={companyColor}
            width="56px"
            height="56px"
            url={checkIcon}
          />
          <SuccessDescription>
            <Trans>
              Your feedback has been sent. A copy of your feedback has been sent to your email.
            </Trans>
          </SuccessDescription>
        </SuccessBlock>
      </SuccessBlockWrapper>
    ) : (
      <GiveFeedbackLocked
        logoUrl={logoUrl}
        createdFor={review.createdFor}
        title={i18n._(t`This feedback request expired`)}
      />
    );
  }

  render() {
    const { review, logoUrl, i18n } = this.props;
    const {
      forUser,
      email,
      isFeedbackActive,
      isFeedbackSigned,
      isStartDateInFuture,
      loading,
      error,
    } = this.state;

    if (error || isFeedbackSigned || isStartDateInFuture) {
      const startDate =
        isStartDateInFuture && moment(get(review, 'startDateUsersRequest')).format('YYYY-MM-DD');
      const title =
        (error && i18n._(t`This feedback request is cancelled, rejected or expired`)) ||
        (isFeedbackSigned &&
          i18n._(t`This review is already signed. You can not give feedback anymore.`)) ||
        (isStartDateInFuture && i18n._(t`This feedback request is not yet available.`));
      return (
        <GiveFeedbackLocked
          logoUrl={logoUrl}
          createdFor={review.createdFor}
          title={title}
          description={isStartDateInFuture && i18n._(t`You can provide feedback from ${startDate}`)}
          isPositionFixed
        />
      );
    }

    return (
      <Wrapper>
        <ShowSpinnerIfLoading loading={loading} height="100%">
          {!isEmpty(review) &&
            (isFeedbackActive ? (
              <GiveFeedback
                ratingType={RATING_TYPES.OUTSIDE_RATING}
                forUser={forUser}
                email={email}
                logoUrl={logoUrl}
              />
            ) : (
              this.renderNotActiveOptions()
            ))}
        </ShowSpinnerIfLoading>
      </Wrapper>
    );
  }
}

export default withI18n()(
  connect((state) => ({
    review: state.currentReview,
    logoUrl: getAppThemeUrl(state),
  }))(withRouter(ReviewGiveFeedbackOutside)),
);
