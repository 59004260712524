export enum API_RETURN_FIELDS {
  /* GENERAL */
  TOTAL = 'total',
  TABS_ITEMS_TOTAL = 'tabsItemsTotal',
  SKIP = 'skip',
  LIMIT = 'limit',

  /* COLLECTIONS */
  ACTIVITIES = 'activities',
  USER_LIBRARY_ITEMS = 'userLibraryItems',
  USER_ACTIVITIES = 'userActivities',
  USER_ACTIVITY = 'userActivity',
  ACTIVITY = 'activity',
  ACTIVITY_CATEGORIES = 'activityCategories',
  ACTIVITY_CATEGORY = 'activityCategory',
  COMPANY_DELETE_REQUESTS = 'companyDeleteRequests',
  COMPANY_DELETE_REQUEST = 'companyDeleteRequest',
  COMPANY = 'company',
  COMPANY_SETTINGS = 'companySettings',
  USER_COMPANIES = 'userCompanies',
  FILTERED_ACTIVITIES = 'filteredActivities',
  RATINGS = 'ratings',
  ROLE_RELEVANCIES = 'roleRelevancies',
  ROLE_RELEVANCY = 'roleRelevancy',
  USERS = 'users',
  CONVERSATIONS = 'conversations',
  KPI = 'kpi',
  KPIS = 'kpis',
  CV_SETTINGS = 'cvSettings',
  PATH = 'path',
  PATHS = 'paths',
  PRODUCTS = 'products',
  USER_PATH = 'userPath',
  USER_PATHS = 'userPaths',
  ACTIVITIES_COMPLETED = 'activitiesCompleted',
  SURVEY = 'survey',
  SURVEYS = 'surveys',
  SURVEY_TASK = 'surveyTask',
  SURVEY_TASKS = 'surveyTasks',
  SURVEY_EVENTS = 'surveyEvents',
  SURVEY_THEMES = 'surveyThemes',
  SURVEY_THEME = 'surveyTheme',
  SURVEY_TEMPLATES = 'surveyTemplates',
  SURVEY_MEMBERS = 'surveyMembers',
  SURVEY_PARTICIPATION = 'surveyParticipation',
  SURVEY_TEMPLATE = 'surveyTemplate',
  SURVEY_QUESTIONS = 'surveyQuestions',
  SURVEY_QUESTION = 'surveyQuestion',
  SURVEY_RATINGS = 'surveyRatings',
  USER_SURVEYS = 'userSurveys',
  USER_SURVEY = 'userSurvey',
  USER_CONNECTION = 'userConnection',
  SURVEY_RESULTS_QUESTIONS = 'surveyResultsQuestions',
  SURVEY_RESULTS_THEMES = 'surveyResultsThemes',
  SURVEY_RESULTS_AVERAGE = 'surveyResultsAverage',
  SURVEY_RESULTS_TEAMS = 'surveyResultsTeams',
  REPORT = 'report',
  REVIEWS = 'reviews',
  CURRENT_CONVO_STEPS = 'currentConvSteps',
  PREVIOUS_INCOMPLETED_STEPS = 'previousUncompletedSteps',
  NEXT_STEPS = 'nextSteps',
  TOTAL_ACTIVE_NEXT_STEPS = 'totalActiveNextSteps',
  TOTAL_NEXT_STEPS = 'totalNextSteps',
  TOTAL_FILTERED_TASKS = 'totalTasks',
  // add more
}
